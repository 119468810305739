// Custom Project Styles
@import "jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

// Unitegallery Styles
@import "unitegallery/unite-gallery";
@import "unitegallery/ug-theme-default";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

// Mixin import & Configuration
@import "mixins/mixins";
$rem-baseline: 10px;

// Theme Import
@import "theme/config";


//Slick Slider
@import "slick/slick.scss";
.slick-slide:focus { outline: none; }
.slick-arrow { position: absolute; border: none; background: transparent; font-size: 0; width: rem(44px); height: rem(44px); background-repeat: no-repeat; background-position: center; top: 50%; transform: translateY(-50%); z-index: 100; opacity: 0.5; transition: all 0.3s; outline: none;}
.slick-arrow:hover { opacity: 1; }
.slick-prev { left: 0; background-image: url('../img/angle-left.svg'); }
.slick-next { right: 0; background-image: url('../img/angle-right.svg'); }
.slick-dots { position: absolute; width: 100%; text-align: center; padding: 0; bottom: rem(30px); }
.slick-dots li { list-style-type: none; display: inline-block; }
.slick-dots li button { border: none; padding: 0; position: relative; width: rem(25px); height: rem(25px); font-size: 0; background: transparent; }
.slick-dots li button:after { content: ""; display: block; position: absolute; background-color: transparent; top: rem(5px); left: rem(5px); width: rem(15px); height: rem(15px); border-radius: 100%; border: 1px solid #fff; opacity: 1; transition: opacity 0.5s; }
.slick-dots li.slick-active button:after { background-color: $theme-primary; }
.slick-dots li button:hover { opacity: 0.75; }
.content-slider .slick-prev { left: -28px }
.content-slider .slick-next { right: -28px }
.content-slider { margin: 0 -$grid-gutter-width/2; }
.content-slider--item { margin: 0 $grid-gutter-width/2; }
#content .slick-slider li { padding: 0; }
#content .slick-slider li:before { background-color: transparent; }


//Animatiertes Menüicon
.mainnav__menu-icon { width: rem(44px); height: rem(44px); position: relative; transform: rotate(0deg); transition: .5s ease-in-out; cursor: pointer; background: none; border: none; }
.mainnav__menu-icon span { display: block; position: absolute; height: rem(2px); width: rem(40px); background: #B17F4A; opacity: 1; left: rem(2px); transform: rotate(0deg); transition: .25s ease-in-out; }
.mainnav__menu-icon span:nth-child(1) { top: 9px; }
.mainnav__menu-icon span:nth-child(2), .mainnav__menu-icon span:nth-child(3) { top: 21px; }
.mainnav__menu-icon span:nth-child(4) { top: 33px; }
.mainnav__menu-icon.open span:nth-child(1) { top: 18px; width: 0; left: 50%; }
.mainnav__menu-icon.open span:nth-child(2) { transform: rotate(45deg); }
.mainnav__menu-icon.open span:nth-child(3) { transform: rotate(-45deg); }
.mainnav__menu-icon.open span:nth-child(4) { top: 18px; width: 0; left: 50%; }
.mainnav__menu-icon:focus { outline: none; }

// Reset Navigation Lists
nav ul { margin: 0; padding: 0; }
nav ul li { list-style-type: none; margin: 0; padding: 0; }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v15-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('../fonts/open-sans-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v15-latin-800.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v15-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* ==========================================================================
   Project's custom styles
   ========================================================================== */

*:focus {
  outline-color: $brand-primary;
}

html {
  font-size: 62.5%;
}

html.no-scroll {
  overflow: hidden;
}

body {
  font-size: rem($font-size-base);
  font-family: $font-family-base;
}

h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: rem(20px);
}

h1 {
  font-size: rem($font-size-h1 * 0.75);
  color: #634E42;
  font-weight: 800;
}

h2 {
  font-size: rem($font-size-h2 / 28 * 22);
  color: #634E42;
  font-weight: 800;
}

h3 {
  font-size: rem($font-size-h3 * 0.8);
  color: #B17F4A;
  font-weight: 600;
}

h4 {
  font-size: rem($font-size-h4 * 0.9);
  color: #B17F4A;
  font-weight: 600;
}

h5 {
  font-size: rem($font-size-h5);
  color: #B17F4A;
  font-weight: 600;
}

h6 {
  font-size: rem($font-size-h6);
  color: #634E42;
  font-weight: 300;
}

p {
  font-size: rem($font-size-base);
  color: $text-color-dark;
  line-height: rem(20px);
}

p.large {
  font-size: rem($font-size-base-large / 18 * 16);
  color: $text-color-dark;
}

a {
  color: $theme-secondary;
  transition: all 0.3s;
}

a:hover {
  color: darken($theme-secondary,10%);
}

#content img {
  max-width: 100%;
}

section.text {
  margin: rem(50px 0);
}

section.text + section.text {
  padding-top: 0;
}

.themecolor {
  color: $theme-secondary;
}

a.mailadress {
  color: $text-color-dark;
  display: inline-block;
  border-bottom: 1px solid $theme-secondary;
}

button:focus,
a:focus {
  outline: transparent!important;
}

.btn {
  display: block;
  border: 1px solid;
  text-align: center;
  font-size: rem($font-size-base);
  line-height: rem($font-size-base);
  border-radius: 0;
  max-width: rem(300px);
  transition: all 0.3s;
  height: rem(40px);
  padding-top: rem(11px);
}

button.btn {
  padding: 0;
}

.btn-white {
  border-color: $theme-secondary;
  color: $theme-secondary;
  box-shadow: inset 0 0 0 0 #FFF;
}

.btn-white:hover {
  background-color: $theme-secondary;
  //box-shadow: inset 0 -66px 0 0 #FFF;
  color: #fff;
}

.btn-themecolor {
  border-color: $theme-primary;
  color: $theme-primary;
}

.btn-themecolor:hover {
  background: $theme-primary;
  color: #FFF;
}

section.bg-color {
  padding: rem(50px 0);
  background-color: $brand-primary;
}

section.bg-color * {
  color: #FFF;
}

.fixed-bg {
  background-attachment: fixed;
}

.product-detail {
  //margin-top: 20px;
}

.product-detail p {
  margin: 0;
}

.product-item {
  position: relative;
  display: block;
  //padding: 10px ;
  //background: linear-gradient(to right, #634e42 0%,#b17f4a 50%, #fff 100%) left bottom no-repeat;
  //background-size: 100% 1px;
  //border: 1px solid $theme-primary;
  margin-bottom: 50px;
  transition: all 0.3s;
}

.product-listing {
  margin-top: 20px;
}

.product-item:hover {
  //border: 1px solid $theme-secondary;
}

.product-item__image:before {
  content: "";
  position: absolute;
  display: block;
  top: 300px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($theme-secondary,0.5);
  transition: top 0.2s;
}

.product-item__image:after {
  font-family: FontAwesome;
  content: "\f067";
  font-size: 32px;
  color: rgba(255,255,255,0.0);
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: color 0.4s 0.1s;
}

.product-item:hover .product-item__name {
  padding-left: 10px;
  color: #FFF!important;
  position: relative;
  z-index: 999;
}

.product-item:hover .product-item__image:before {
  top: -40px;
}

.product-item:hover .product-item__image:after {
  color: rgba(255,255,255,0.5);
}

.product-item__image {
  position: relative;
  display: block;
}

.product-item__image img {
  width: 100%;
}

.product-item__name {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 0;
  color: $theme-secondary;
  transition: all 0.2s;
}

.product-variants {
  margin-bottom: 20px;
}

.product-variants .white-box {
  background: white;
  height: auto;
  //box-shadow: 0 0 5px #ccc;
  margin-bottom: 16px;
  padding-bottom: 75%;
}

.product-variants .thumbnails input[name="select"] {
  display: none;
}

.thumb-label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    width: 100%;
    position: absolute;
    bottom: -18px;
    left: 0;
  }
}

.product-variants img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.product-variants .pv-container {
  position: relative;
}

html.flexbox .product-variants .thumbnails {
  display: flex!important;
}

html.flexbox .product-variants .thumbnails > li {
  flex: 1;
  max-width: 33.33333%;
}

.product-variants .thumbnails {
  list-style: none;
  font-size: 0;
}

.product-variants .thumbnails li {
  position: static!important;
  width: 110px;
  height: auto;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  float: left;
  display: block;
  box-shadow: 0 0 5px #ccc;
  padding: 0!important;
  margin-bottom: 15px!important;
}

.product-variants .thumbnails li:not(:last-of-type) {
  margin-right: 16px!important;
}

.product-variants .thumbnails li:before {
  content: none!important;
}

.product-variants .thumbnails .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: all 150ms linear;
  padding: 0;
}

.product-variants .thumbnails input[name="select"]:checked ~ .content {
  opacity: 1;
}

.product-variants .thumbnails input[name="select"]:checked ~ .item-hugger label span {
  font-weight: 600;
}

.item-hugger {
  height: 100%;
  cursor: pointer;
  position: relative;
}

.product-variants .thumb-image {
  //max-height: 90px;
}

.item-wrapper {
  box-shadow: 0 0 5px #ccc;
  height: auto;

  img {
    max-width: none!important;
    width: 100%;
  }
}

.modal-content {
  border-radius: 0;
}

.modal-backdrop {
  background-color: $theme-secondary;
}

.modal-backdrop.in {
  opacity: 0.75;
}

.modal-footer .btn {
  display: inline-block;
  padding: 5px 10px;
}

.product-detail .btn.btn-themecolor {
  background-color: $theme-primary;
  color: #FFF;
  margin-top: 20px;
}

.modal-body input, .modal-body textarea {
  border-radius: 0;
}

.modal-header .close {
  font-size: 27px;
}


@media (min-width: 600px) and (max-width: 767px) {

  .productlist .col-xs-12.col-xs-sm-6 {
    width: 50%;
  }

  .product-item__name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}

//Animiertes Unterstreichen von der Mitte ausgehend
.underline a {
  position: relative;
  color: #FFF;
  text-decoration: none;
}
.underline a:hover {
  color: #FFF;
}
.underline a:before {
  content: "";
  position: absolute;
  width: 140%;
  height: rem(40px);
  bottom: 0;
  left: -20%;
  background-color: rgba(255,255,255,0.1);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s;
}
.underline a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.product-data td:first-of-type {
  //background: $theme-primary;
  color: $theme-primary;
  font-weight: 600;
}

.product-data tr {
  border-bottom: 1px solid #eee;
}

.product-data td {
  padding: 2px 20px 2px 0;
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  h1 {
    font-size: rem($font-size-h1 * 0.65);
  }

  h2 {
    font-size: rem($font-size-h2 * 0.7);
  }

  h3 {
    font-size: rem($font-size-h3 * 0.8);
  }

  h4 {
    font-size: rem($font-size-h4 * 0.9);
  }

  p.large {
    font-size: rem($font-size-base-large);
    line-height: rem(36px);
  }

  .container--slim {
    padding: 0 10%;
  }

  .product-item__name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  h1 {
    font-size: rem($font-size-h1);
  }

  h2 {
    font-size: rem($font-size-h2);
  }

  h3 {
    font-size: rem($font-size-h3);
  }

  h4 {
    font-size: rem($font-size-h4);
  }

  h5 {
    font-size: rem($font-size-h5);
  }

  section.bg-color {
    padding: rem(100px 0);
    background-color: $brand-primary;
  }

  .content_with_sidemenu {
    margin-top: 20px;
  }

  .content_with_sidemenu .container {
    width: 100%!important;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .container {
    width: 1170px;
  }

}