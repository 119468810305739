.mapcontact {
  position: relative;
  overflow: hidden;
}

.mapcontact__map-contactoverlay {
  width: 100%;
  height: rem(400px);
}

.mapcontact__contact {
  background-color: transparentize($theme-secondary,0.3);
  padding: rem(50px 15px);
}

.mapcontact__contact h3 {
  color: #FFF;
  line-height: rem(26px);
}

.mapcontact input {
  border: 1px solid #FFF;
  height: rem(60px);
  width: 100%;
  margin-bottom: rem(30px);
  padding: rem(0 15px);
  border-radius: 0!important;
  box-shadow: none!important;
  -webkit-appearance: none;
  background-color: transparent;
  color: #FFF;
}

.mapcontact textarea {
  border: 1px solid #FFF;
  height: rem(200px);
  width: 100%;
  margin-bottom: rem(15px);
  padding: rem(75px 15px 15px 15px);
  border-radius: 0!important;
  box-shadow: none!important;
  -webkit-appearance: none;
  color: #FFF;
  background-color: transparent;
}

.contact__form-btn {
  background: none;
  width: auto;
  float: right;
  border-color: #FFF;
  color: #FFF;
}

.input-wrapper {
  display: block;
  position: relative;
}

.input-wrapper label {
  position: absolute;
  height: rem(60px);
  width: 100%;
  text-align: left;
  background: transparent;
  top: 0;
  right: 0;
  padding-left: rem(15px);
  line-height: rem(60px);
  font-weight: 400;
  transition: all 0.3s;
  color: #FFF;
  font-size: 16px;
}

.input-wrapper textarea:focus,
.input-wrapper input:focus {
  outline: none;
  border-color: #FFF;
}

.input-wrapper input.hasUserInput + label,
.input-wrapper input:focus + label {
  width: 30%;
  background-color: #FFF;
  color: $theme-secondary;
  font-size: rem(14px);
}

.input-wrapper textarea.hasUserInput + label,
.input-wrapper textarea:focus + label {
  background-color: #FFF;
  color: $theme-secondary;
  font-size: rem(14px);
}

.mapcontact.horizontal .contact__form-btn.btn {
  margin: 0 auto;
  float: none;
  padding-left: rem(50px);
  padding-right: rem(50px);
}

.mapcontact.horizontal .contact__form-btn.btn:hover {
  background: #FFF;
  color: $theme-primary;
}

.mapcontact.horizontal h2 {
  color: #FFF;
  text-align: center;
  margin-bottom: rem(30px);
}

.mapcontact.horizontal {
  position: relative;
  //margin: rem(0 -15px);
  background-color: transparentize($theme-secondary,0.3);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: rem(50px 0);
  height: auto;
  box-shadow: inset 0 0 0 1500px transparentize($theme-secondary,0.2);
}

.mapcontact.horizontal .mapcontact__contact {
  padding: rem(50px 0);
  background-color: transparent;
}

.mapcontact.largeimage {
  height: auto;
}

.mapcontact.largeimage .mapcontact__map-contactoverlay {
  height: rem(400px);
  width: 100%;
}

.mapcontact.largeimage .mapcontact__panorama {
  height: rem(600px);
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px transparentize($theme-secondary,0.5);
  background-attachment: fixed;
}

.mapcontact.largeimage .btn {
  padding-left: rem(50px);
  padding-right: rem(50px);
}

.mapcontact.largeimage .btn:hover {
  background-color: #FFF;
  color: $theme-primary;
}

.mapcontact.largeimage .mapcontact__address {
  color: #FFF;
  font-size: rem(16px);
  margin-bottom: rem(40px);
}

.mapcontact.largeimage .mapcontact__address i.fa {
  display: block;
  color: #FFF;
  font-size: rem(50px);
  margin-bottom: rem(10px);
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .mapcontact {
    position: relative;
    height: rem(750px);
  }

  .mapcontact__map-contactoverlay {
    height: 100%;
  }

  .mapcontact__contact {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
  }

  .mapcontact h3 {
    margin-bottom: rem(50px);
  }


  .mapcontact.horizontal .mapcontact__contact {
    position: relative;
    background: none;
    width: 100%;
    padding: 0;
  }

  .mapcontact.horizontal .mapcontact__map-contactoverlay {
    height: rem(350px);
    margin-bottom: rem(30px);
  }

  .mapcontact.horizontal .container.slim {
    max-width: rem(960px);
  }

  .mapcontact.horizontal {
    margin: 0;
    width: 100%;
  }

  .mapcontact.largeimage .mapcontact__contact {
    width: rem(700px);
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: rem(-350px);
    top: auto;
    height: auto;
    background-color: transparentize($theme-secondary,0.1);
    padding: rem(30px 50px);
  }

  .mapcontact.largeimage .mapcontact__map-contactoverlay {
    height: rem(800px);
  }

  .mapcontact.largeimage .mapcontact__panorama {
    height: rem(600px);
  }

  .mapcontact.largeimage .contact__form input,
  .mapcontact.largeimage .contact__form textarea,
  .mapcontact.largeimage .contact__form .contact__form-btn {
    border-color: rgba(255,255,255,0.5);
  }

  .mapcontact.horizontal .contact__form input,
  .mapcontact.horizontal .contact__form textarea,
  .mapcontact.horizontal .contact__form .contact__form-btn {
    border-color: rgba(255,255,255,0.5);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .mapcontact {
    height: rem(800px);
  }

  .mapcontact__contact {
    width: 40%;
    padding: rem(70px 50px 70px 60px);
  }

  .mapcontact.largeimage .mapcontact__contact {
    width: rem(1140px);
    margin-left: rem(-570px);
    padding-left: rem(100px);
    padding-right: rem(100px);
  }

}


@media (min-width: 1600px) {

  .mapcontact__contact {
    padding: rem(70px 100px 70px 120px);
  }

}


/*
.contact {
  padding: rem(50px 0);
}

.contact__intro {
  text-align: center;
  margin-bottom: rem(20px);
}

.contact__form-btn:hover {
  background: $theme-secondary;
  color: #FFF;
  border-color: $theme-secondary
}

.contact__contactlist {
  margin: rem(0 0 50px 0)!important;
  padding: 0;
}

.contact__contactlist-item {
  list-style-type: none;
  width: 100%;
  margin-bottom: rem(10px);
}

.contact__contactlist-icon {
  position: relative;
  display: inline-block;
  width: rem(24px);
  height: rem(24px);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  top: rem(6px);
  margin-right: rem(10px);
}

.contact__map {
  margin-bottom: rem(50px);
}

.contact__map * {
  color: $text-color-dark!important;
}

.contact__map a,
.contact__map a * {
  color: $theme-secondary!important;
}

.contact ul li:before {
  content: none!important;
}

.contact.contact--bgcolor {
  background-color: $theme-secondary;
}

.contact.contact--bgcolor * {
  color: #fff;
}

.contact.contact--bgcolor .contact__contactlist-item,
.contact.contact--bgcolor .contact__contactlist-icon {
  color: #fff;
}

.contact.contact--bgcolor .contact__form-btn {
  border-color: #fff;
  color: #fff;
}

.contact.contact--bgcolor .input-wrapper input {
  border-color: darken($theme-secondary, 5%);
  background-color: darken($theme-secondary, 5%);
}

.contact.contact--bgcolor .input-wrapper textarea {
  border-color: darken($theme-secondary, 5%);
  background-color: darken($theme-secondary, 5%);
}

.contact.contact--bgcolor .input-wrapper input.hasUserInput+label,
.contact.contact--bgcolor .input-wrapper input:focus+label {
  background-color: #FFF;
  color: darken($theme-secondary, 5%);
}

.contact.contact--bgcolor .input-wrapper textarea.hasUserInput+label,
.contact.contact--bgcolor .input-wrapper textarea:focus+label {
  background-color: #FFF;
  color: darken($theme-secondary, 5%);
}

.contact__form {
  max-width: rem(800px);
  margin: 0 auto;
}

.contact.contact--map .contact__contactlist-item {
  text-align: center;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .input-wrapper input.hasUserInput + label,
  .input-wrapper input:focus + label {
    font-size: rem(16px);
  }

  .input-wrapper textarea.hasUserInput + label,
  .input-wrapper textarea:focus + label {
    font-size: rem(16px);
  }

  .contact__form-btn {
    width: rem(250px);
  }

  .contact__map {
    margin-bottom: rem(100px);
  }

  .contact.contact--nomap .contact__contactlist {
    width: 100%;
    max-width: rem(800px);
    text-align: center;
    margin-left: auto!important;
    margin-right: auto!important;
  }

  .contact.contact--nomap .contact__contactlist-item {
    width: 33.3333%;
    float: left;
  }

  .flexbox .contact.contact--nomap .contact__contactlist {
    display: flex;
    justify-content: space-around;
  }

  .flexbox .contact.contact--nomap .contact__contactlist-item {
    width: auto;
    float: none;
  }

  .contact.contact--nomap .contact__form-btn {
    margin: 0 auto;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  input {
    height: rem(60px);
  }

  .input-wrapper label {
    height: rem(60px);
    line-height: rem(60px);
  }

  .contact__form-btn {
    background: none;
    width: rem(300px);
    height: rem(60px);
  }

  .input-wrapper label {
    height: rem(60px);
  }

  .input-wrapper input.hasUserInput + label,
  .input-wrapper input:focus + label {
    width: 25%;
  }

  textarea {
    height: rem(220px);
    padding: rem(75px 15px 15px 15px);
  }


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  input {
    margin-bottom: rem(20px);
  }

  textarea {
    height: rem(250px);
    margin-bottom: rem(20px);
  }

  .contact__map {
    margin-bottom: rem(90px);
  }

  .contact__form-btn {
    padding: rem(15px 12px)!important;
  }

}

*/