.mainpic {
  position: relative;
  width: 100%;
  height: rem(480px);
  overflow: hidden;
}

.mainpic.slim {
  height: rem(240px);
}

.mainpic--slider .slick-list, .mainpic--slider .slick-track {
  height: 100%;
}

.mainpic__container {
  position: relative;
  height: 100%;
}

.mainpic__item {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.mainpic__itemoverlay {
  position: absolute;
  bottom: rem(100px);
  background-color: #fff;
  width: rem(300px);
  padding: rem(30px 10px 20px 10px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.mainpic--transparent-overlay .mainpic__itemoverlay {
  background-color: transparentize($theme-primary,0.25);
  max-width: rem(560px);
}

.mainpic--transparent-overlay .mainpic__itemoverlay h2 {
  color: #FFF;
}
.mainpic--transparent-overlay .mainpic__itemoverlay .btn.btn-white {
  color: #FFF;
  border-color: #FFF;
}

.mainpic__itemoverlay h2 {
  color: $theme-secondary;
  font-weight: 800;
}

.mainpic__itemoverlay p {
  color: $theme-primary;
}

.mainpic--full-overlay .mainpic__container {
  position: static;
}

.mainpic--full-overlay .mainpic__itemoverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: none;
}

header + #content > div:first-child > .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
  top: rem(60px);
}

.mainpic--full-overlay .mainpic__itemoverlay-content {
  position: absolute;
  bottom: rem(40px);
  width: rem(280px);
  left: 50%;
  margin-left: rem(-140px);
}

.mainpic__itemoverlay-content .btn {
  margin: rem(20px auto 0 auto);
  max-width: rem(150px);
}

.mainpic__item:not(:first-of-type) {
  display: none;
}

.mainpic__item.mainpic--slider:not(:first-of-type) {
  display: block;
}

.mainpic--piconly-small .mainpic__itemoverlay,
.mainpic--piconly-mid .mainpic__itemoverlay,
.mainpic--piconly-large .mainpic__itemoverlay {
  display: none!important;
}

.mainpic.mainpic--piconly-small {
  height: rem(60px);
}

.mainpic.mainpic--piconly-small .slick-dots {
  display: none!important;
}

.mainpic.mainpic--piconly-mid  {
  height: rem(200px);
}

.mainpic.mainpic--piconly-mid .slick-dots {
  display: none!important;
}

.mainpic.mainpic--nobg .mainpic__itemoverlay {
  background: transparent;
}

.mainpic.mainpic--nobg .mainpic__itemoverlay * {
  color: $text-color-dark;
}

.mainpic.mainpic--nobg .mainpic__itemoverlay .btn {
  border-color: $text-color-dark;
}

.mainpic.mainpic--nobg .mainpic__itemoverlay .btn:hover {
  background: $theme-primary;
  border-color: $theme-primary;
  color: #fff;
}

.mainpic.mainpic--textonly-overlay .mainpic__itemoverlay {
  background: none;
  padding: 0;
  top: 50%;
  text-shadow: 0 0 20px rgba(0,0,0,1);
}

.mainpic.mainpic--textonly-overlay.mainpic--center .mainpic__itemoverlay {
  transform: translate(-50%,-50%);
  bottom: auto;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  header + #content > div:first-child > .mainpic {
    top: 0;
    //margin-bottom: rem(-150px);
  }

  .mainpic__itemoverlay {
    width: rem(480px);
    padding: rem(30px 15px 25px 15px);
  }

  header + #content > div:first-child > .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    //top: rem(150px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    width: rem(420px);
    margin-left: rem(-210px);
  }

  .mainpic--left-overlay .mainpic__itemoverlay {
    left: 10px;
    transform: none;
    text-align: left;
  }

  .mainpic--left-overlay .mainpic__itemoverlay .btn {
    margin: rem(20px 0 0 0);
  }

  .mainpic--right-overlay .mainpic__itemoverlay {
    left: auto;
    right: 10px;
    transform: none;
    text-align: left;
  }

  .mainpic--right-overlay .mainpic__itemoverlay .btn {
    margin: rem(20px 0 0 0);
  }

  .itemoverlay__container {
    position: relative;
    height: 100%;
    max-width: 100%;
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay {
    right: 0;
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    left: auto;
    right: 0;
  }

  .mainpic--full-overlay.mainpic--left-overlay .mainpic__itemoverlay {
    left: 0;
  }

  .mainpic--full-overlay.mainpic--left-overlay .mainpic__itemoverlay-content {
    left: 0;
    right: auto;
    margin: 0;
  }

  .mainpic.mainpic--piconly-small {
    height: rem(150px);
  }

  .mainpic.mainpic--piconly-mid  {
    height: rem(350px);
  }

  .mainpic.mainpic--center.mainpic--transparent-overlay .mainpic__itemoverlay {
    top: 0;
    bottom: 0;
  }

  .mainpic.mainpic--center.mainpic--transparent-overlay .mainpic__itemoverlay-content {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }

  .mainpic.mainpic--center.mainpic--transparent-overlay .slick-dots li button:after {
    border-color: #FFF;
  }

  .mainpic.mainpic--center.mainpic--transparent-overlay .slick-dots li.slick-active button:after {
    background-color: #FFF;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  header + #content > div:first-child > .mainpic {
    //top: rem(-200px);
    //margin-bottom: rem(-200px);
  }

  .mainpic__itemoverlay {
    width: rem(500px);
    padding: rem(30px 15px 25px 15px);
  }

  header + #content > div:first-child > .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    //top: rem(200px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    width: rem(500px);
    margin-left: rem(-250px);
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    width: rem(500px);
  }

  .mainpic.mainpic--piconly-small {
    height: rem(200px);
  }

  .mainpic.mainpic--piconly-mid  {
    height: rem(500px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  header + #content > div:first-child > .mainpic {
    //top: rem(-250px);
    //margin-bottom: rem(-250px);
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    width: rem(500px);
  }

  header + #content > div:first-child > .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    //top: rem(250px);
  }

  .mainpic.mainpic--piconly-small {
    height: rem(250px);
  }

}